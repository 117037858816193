<template>

<button v-if="!inventory || (inventory && modifier.quantity > 0)" :class="(isSelected) ? 'selected' : ''" ><h2>{{ modifier.name }}</h2><div class="desc">
    <p class="pos-neg-buttons"> 
      <b class='cart_symbol' @click="addOverride(-1)"><font-awesome-icon class='quantity-icon' icon="minus-circle"/></b>
          <span class="quantity">{{ this.quantity }}</span>
      <b class='cart_symbol' @click="addOverride(1)"><font-awesome-icon class='quantity-icon' icon="plus-circle"/></b>
    </p>
  </div><span v-if="inventory">Quantity: {{ modifier.quantity }}</span></button>
</template>

<script>

export default {
  name: 'ItemQuantitySetter',
  props: ['modifier', 'modifierSet', 'selectedModifiersSets'],
  data() {
    return {
      isSelected: this.selected,
      price: this.modifier.price,
      quantity : 0
    }
  },
  methods: {
    addModifier() {
      
      this.isSelected = ! this.isSelected;
      this.$emit('addModifier', { modifier: this.modifier, modifier_set: this.modifierSet, selected: this.isSelected});
    },
    addOverride(action) {
      if((this.quantity > 0 || (this.quantity == 0 && action == 1)) && (this.quantity < this.modifier.quantity || (this.quantity == this.modifier.quantity && action == -1))){
        this.quantity += action;
        if(action == 1){
          this.$emit('addOverrideMod', { modifier: this.modifier, modifier_set: this.modifierSet});
        }else{
          this.$emit('removeOverrideMod', { modifier: this.modifier, modifier_set: this.modifierSet});

        }
      }else{
        this.isSelected = false;
      }
    
      
    },
  },
  mounted() {
    this.isSelected = this.selected
  },
  computed: {
    selected() {
      return Boolean(this.selectedModifiersSets &&
          this.modifierSet &&
          this.modifierSet._id &&
          this.selectedModifiersSets[this.modifierSet._id] &&
          this.selectedModifiersSets[this.modifierSet._id].find(i => i.name === this.modifier.name))
    },
    inventory() {
      return this.modifierSet.rules && this.modifierSet.rules.inventory;
    }
  },
}

</script>

<style lang="scss" scoped>

button {
  margin: 0.2rem;
  text-transform: capitalize;
  background-color: var(--primary-colour);
  color: var(--text-colour);
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  gap: 1rem;
  .pos-neg-buttons{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }

  h2{
    margin-top: 0;
  }
}

.selected {
    background-color: var(--action-colour) !important;
    color: var(--hover-colour);
}

.desc{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 0.25rem;
  height: 1rem;
}

.cart_symbol{
  font-size: xx-large;
  color: var(--action-colour);
}

</style>
