<template>
  <teleport to="body">
    <div
      v-if="openModal"
      class="modal"
      @click.self.prevent="
        openModal = !openModal;
        openNotesModal = false;
      ">
      <div class="product">
        <font-awesome-icon icon="fa-circle-xmark" class="exit" @click="openModal = !openModal" />
        <div v-if="futurePickup">
          <h3>Thanks {{ getWebOrder.order.name }}, a receipt and confirmation has been sent to {{ getWebOrder.order.email }}.</h3>
          <h3>Your order will be ready to collect at our {{ getWebOrder.order.location }} shop on {{ formattedDate }}.</h3>
        </div>
        <div v-else>
          <h2>Order Status: <span v-if="orderComplete">Complete</span><span v-else>In Progress</span></h2>
          <h3>Thanks {{ getWebOrder.order.name }}, a receipt and confirmation has been sent to {{ getWebOrder.order.email }}.</h3>
          <h3>Collect from the team at approximately {{ pickupTime }}.</h3>
        </div>

        <div>
          <div>
            <ul class="modifiers">
              <div v-for="product in getWebOrder.order.products" :key="product.hash" class="modifiers-layout">
                <h3>{{ product.name }} <font-awesome-icon v-if="product.complete" icon="fa-circle-check" /></h3>
                <p v-if="product.modifier_list.length != 0">Modifiers:</p>
                <p v-for="mod in product.modifier_list" :key="mod">{{ mod.name }}</p>
                <p v-for="event in product.events" :key="event">&#9825; from {{ event.station }} @ {{ getLocalTime(event.time) }}</p>
              </div>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "WebOrderModal",
  props: ["open", "close"],
  data() {
    return {
      openModal: false,
      openNotesModal: false,
      orderStatus: "In Progress",
    };
  },

  mounted() {
  },
  
  methods: {
    ...mapActions(["updateWebOrder"]),
    getLocalTime(time) {
      const newTime = new Date(time);
      return newTime.getHours() + ":" + newTime.getMinutes();
    },
  },

  emits: ["showWebOrderModal"],

  computed: {
    ...mapGetters(["getWebOrder", "futurePickup"]),

    orderComplete() {
      return this.getWebOrder.order.products.every((product) => product.complete);
    },

    formattedDate() {
      const date = new Date(this.getWebOrder.order.pickup_date);
      return date.toDateString();
    },

    pickupTime() {
      const start = Date.parse(this.getWebOrder.order.events.created_at);
      const approx = start + 600000;
      console.log(approx);
      const mins = new Date(approx).getMinutes() < 10 ? "0" + new Date(approx).getMinutes() : new Date(approx).getMinutes();
      return new Date(approx).getHours() + ":" + mins;
    }
  },
  watch: {
    open() {
      this.openModal = true;
      if (localStorage.getItem("orderID") !== null && !("order" in this.getWebOrder)) {
        this.$parent.$parent.channel.push("order:status", localStorage.getItem("orderID")).receive("ok", async (reply) => {
          this.updateWebOrder({ order: reply.reply.rows[0].doc });
        });
      }
    },
    close() {
      this.openModal = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "public/layout";
@import "public/actions";
@import "public/wrapper";
@import "public/toggle";
@import "public/search";
.modal {
  .product {
    h3 {
      margin: 0.5rem;
    }
    .modifiers {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-evenly;
      align-items: flex-start;
      overflow: scroll;

      .modifiers-layout {
        flex: 0 0 25%;
        background-color: var(--primary-colour);
        padding: 1rem;
        border-radius: 0.25rem;
        margin: 1rem;
        @media only screen and (orientation: portrait) {
          flex: 0 0 75%;
        }
        p,
        h3 {
          margin: 0;
        }
        svg {
          font-size: 2rem;
          color: var(--action-colour);
          float: right;
        }
      }
    }
  }
}
</style>
