<template>
  <div :class="'modifierBox ' + (remaining ? 'unpaid' : '')">
    <li>
      <div class="box">
        <p style="text-align: center;">
          <span class="modifier-name">{{ order.name || "No Name / Table No." }}<b class="name_right">{{ order.delivery_option }}</b></span>
          <span class="modifier-price">{{ time }}</span>
          <span class="modifier-products">
            <ul>
              <li class="lineItem" v-for="product in order.products" v-bind:key="product.hash">
                <div class="lineItemSummary">
                  <span class="lineItemName">{{ product.item }} <span class="times"> x </span>{{ product.quantity }}</span>
                  <span class="lineItemTotal">{{ '$' + (product.price * product.quantity).toFixed(2) }}</span>
                </div>
                <ul class="modifiers">
                  <li v-for="modifiers in product.modifiers" :key="product.hash + '-' + modifiers">
                    <div v-for="modifier in modifiers" :key="modifier">
                      <span class="submodifier-name">{{ modifier.name }}</span>
                      <span class="submodifier-total">{{ modifier.price ? '$' + modifier.price.toFixed(2) :'' }}</span>
                    </div>
                  </li>
                </ul>
              </li>
            </ul>
          </span>
          <span class="modifier-total">
            <span>Total: </span><span>{{ renderTotal }}</span>
          </span>
          <span class="modifier-total">
            <span>Paid: </span><span> {{ renderPaid }}</span>
          </span>
          <span class="modifier-total">
            <span>Balance: </span><span> {{ renderRemaining }}</span>
          </span>
        </p>
      </div>
    </li>
  </div>
</template>

<script>
import dayjs from "dayjs";

export default {
  name: 'HistoryOrder',
  props: ['order'],
  methods: {
    renderCurrency(value) {
      return '$' + (value ? value.toFixed(2) : '0.00');
    }
  },
  computed: {
    time() {
      var d = dayjs(this.order.created_at);
      return d.format("hh:mma DD/MM/YYYY");
    },
    total() {
      if (this.order.products !== undefined) {
	return this.order.products.reduce((runningTotal, product) => {
          return runningTotal + (product.price * product.quantity)
	}, 0);
      } else {
	return 0;
      }
    },
    paid() {
      if (this.order.payments !== undefined) {
	return this.order.payments.reduce((runningTotal, payment) => {
          return runningTotal + (payment.value)
	}, 0);
      } else {
	return 0;
      }
    },
    remaining() {
      return this.total - this.paid;
    },
    renderTotal() {
      return this.renderCurrency(this.total);
    },
    renderRemaining() {
      return this.renderCurrency(this.remaining);
    },
    renderPaid() {
      return this.renderCurrency(this.paid);
    }
  }
}
</script>

<style scoped lang="scss">
@import "public/layout";
@import "public/wrapper";
@import "public/actions";

.unpaid {
    background-color: var(--secondary-colour)!important;
    
}

.name_right {
    float: right;
}

h2 {
  margin-top: 0px;
  padding-top: 1rem;

  span:nth-of-type(2) {
    color: #aaa;
  }

  cursor: pointer;
}

ul.wrapper {
  grid-template-columns: repeat(1, minmax(0, 1fr));

  .modifierBox {
    .modifier-total {
      display: flex;
      justify-content: space-between;
      font-weight: bold;
    }

    li.lineItem {
      margin-bottom: 1rem !important;

      .lineItemSummary {
        display: flex;

        .lineItemName {
          flex: 1 1;
          display: inline-block;
          text-align: left;
        }

        .lineItemTotal {
          flex: 0 0;
          display: inline-block;
          text-align: right;
        }
      }
    }

    ul.modifiers {
      border-bottom: 1px solid #ccc;
      font-size: 0.8em;

      li{
        list-style: none;
      }

      li div {
        display: flex;
        .submodifier-name {
          flex: 1 1;
          display: inline-block;
          text-align: left;
        }

        .submodifier-total {
          flex: 0 0;
          display: inline-block;
          text-align: right;
        }
      }

      margin: 0px;
      padding: 0px;
    }
  }
}
</style>
